<template>
    <div class="user-content">
        <div class="user-info-title">个人信息</div>
        <div class="user-info-list">
            <div>
                <span>用户名</span>
                <span>{{userInfo.username}}</span>


            </div>
            <div>
                <span>实名信息</span>
                <span>{{ userInfo.name==null || userInfo.name=='' ?'未认证':userInfo.name}}</span>
            </div>
            <div>
                <span>手机号码</span>
              <span> {{userInfo.phone!=null && userInfo.phone!=undefined?  userInfo.phone?.substr(0,3)+"****"+userInfo.phone?.substr(7):""}} </span>
            </div>
            <div>
                <span>身份证</span>
              <span>{{userInfo.idCard!=null && userInfo.idCard!=undefined?  userInfo.idCard?.substr(0,6)+"****"+userInfo.idCard?.substr(14):"未认证"}}</span>
            </div>

          <div style="text-align: center;margin-top:20px;">
            <span></span>
            <span>



       <el-popover placement="bottom" :width="400" trigger="click" v-if="userInfo.idCard==null || userInfo.idCard==undefined ">
      <template #reference>
        <el-button style="margin-right: 16px">扫码进行认证</el-button>
      </template>
      <img src="../../assets/2.png" />
    </el-popover>
            </span>

          </div>
        </div>

    </div>

</template>
<script setup>
import CommonService, {putUserINfo} from '../../service/CommonService';
import { onMounted, reactive,ref} from 'vue'

const visible = ref(false)
const userInfo = reactive({
    avatar: '',
    username: '',
  password: '',
    name: '',
  idCard: '',
    phone: '',
  email: ''
});

onMounted(() => {
    loadUserInfo()
})

const loadUserInfo = () => {
    CommonService.getUserInfo().then(res => {
        if (res && res.data) {
            Object.assign(userInfo, res.data.sysUser)
        }
    })

  CommonService.getidCardInfo().then(ress => {
    console.log(ress)
    if (ress && ress.data) {
      userInfo.idCard=ress.data.idCard;

    }
  })
}

const saveUserInfo = () => {
  CommonService.getUserInfo().then(res => {
    if (res && res.data) {
      Object.assign(userInfo, res.data.sysUser)
      CommonService.getUserInfoById(res.data.sysUser.userId).then(ress => {
        if (ress && ress.data) {

          ress.data.username=userInfo.username;
          ress.data.password=userInfo.password;
          ress.data.phone=userInfo.phone;
          ress.data.email=userInfo.email;
          CommonService.putUserINfo(ress.data).then(resdds => {
            console.log(111)
            console.log(resdds)

          })

        }
      });
    }
  })
}



function tmPhone(val) {
    if (!val) return "";
    //*电话脱敏
    return val.replace(/^(.{3}).*(.{4})$/, "$1****$2");
}
function tmIdCard(val) {
    if (!val) return "";
    //*身份证号脱敏
    return val.replace(/^(.{3}).*(.{4})$/, "$1*******$2");
}
</script>
<style lang="scss" scoped>
.user-content {
    padding: 20px;
    background-color: #fff;
}

.user-info-title {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 20px;
}

/**user info table  */
.user-info-list {
    // width: 100%;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    padding: 20px;

    div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        span {
            font-size: 14px;
            color: #333333;

            &:first-child {
                width: 100px;
            }
        }
    }
}</style>
